import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";

import styled from "styled-components";

import { Grid, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import Loading from "../../../components/conector-ui/conector-loading";
import InternalPageTitle from "../../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../../components/conector-ui/conector-styled-components/styled-paper";
import ConectorDatePicker from "../../../components/conector-ui/conector-date-picker";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import ConectorCheckbox from '../../../components/conector-ui/conector-checkbox'
import ConectorExpandableTable from "./expandable-table";

import { setBreadcrumb } from "../../../store/app-state/actions";

import Translate from "../../../i18n/translate";

import InterfacesService from "../interfaces-service";

import moment from "moment";

import InterfacesAuditLogs from "./resume-log/resume-log";

moment.locale("pt-br");

const StyledGrid = styled(Grid)`

    && {
        position: relative;

        > div:nth-child(3) {
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            top: 12px;
            position: absolute;
            z-index: 3;
            background-color: #FFF;
        }
    }

`;

class InterfacesLogs extends Component {

    constructor(props) {
        super(props);

        let interfaceID = window.location.hash.substring(18)

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "interfaces",
            url: "#/interfaces"
        }, {
            label: "interface_log"
        }])

        if (!interfaceID) {
            window.location.replace("#/interfaces")
        }

        this.state = {
            ...props,
            gridOptions: {
                ...props.gridOptions,
                findPage: this.findPage.bind(this)
            },
            objectFilter: {
                filterStart: new Date(),
                filterEnd: new Date()
            },
            filtered : false,
            dialogOpenAuditLog: false,
            interfaceID: interfaceID,
            filterSuccess: true,
            filterWarning: true,
            filterError: true,
            withFilterDate: true
        };
    }

    handleChangeDate = e => {
        let objectFilter = { ...this.state.objectFilter };
        const { name, value } = e.target;

        if (value != null && value !== "") {
            objectFilter[name] = value.replace("Z", "999Z");
        } else {
            const currentDate = new Date();
            currentDate.setMilliseconds(999);
            objectFilter[name] = currentDate.toISOString();
        }

        this.setState({ objectFilter }, () => {
            this.setState({ 
                withFilterDate: true     
            }, () => {
                this.bringLogData(0, true);
            });
        });
    }

    componentWillMount = () => {
        if (this.state.filtered == false){
            this.findPage(0);
        }
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().replace("Z", "999Z");
        
        // Definindo a data 7 dias atrás
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 2);
        sevenDaysAgo.setHours(0, 0, 0, 0); // Definindo as horas para 00:00
        const formattedSevenDaysAgo = sevenDaysAgo.toISOString().replace("Z", "999Z");
        
        this.setState(prevState => ({
            objectFilter: {
                ...prevState.objectFilter,
                filterStart: formattedSevenDaysAgo,
                filterEnd: formattedCurrentDate
            }
        }), () => {
            this.bringLogData(0, true);
        });
        
    }

    bringLogData = ( currPage, showRemoveFilterButton ) => {
        const {
            gridOptions,
            objectFilter,
            interfaceID,
            filterSuccess,
            filterWarning,
            filterError,
            withFilterDate
        } = this.state;
        this.setState({
            loading: true
        }, () => {
            InterfacesService
                .GetLogsFiltered( interfaceID, objectFilter.filterStart, objectFilter.filterEnd, gridOptions.currPage, filterError, filterSuccess, filterWarning, withFilterDate )
                .then(dataSource => {
                    if (dataSource.logs) {
                        dataSource.logs.forEach(e => {
                            e.logInterfaceDetails.forEach(det =>
                                det.dateTime = moment(det.dateTime).format("LLLL")
                            );

                            e.detailsCount = e.logInterfaceDetails.length;
                        });
                    }
                    this.setState({
                        dataSource: dataSource || [],
                        gridOptions: {
                            ...gridOptions,
                            currPage: gridOptions.currPage
                        },
                        loading: false,
                        filtered : showRemoveFilterButton 
                    });
                })
        });
    }

    findPage = (currPage) => {
        const {
            filtered,
            loading,
            gridOptions,
            interfaceID,
            filterSuccess,
            filterWarning,
            filterError,
          } = this.state;
        
          if (filtered && !loading) {
            this.setState(
              {
                loading: true,
                gridOptions: {
                  currPage: currPage
                }
              },
              () => {
                this.bringLogData(currPage, false);
              }
            );
          } else {
            this.setState(
              {
                loading: true,
                gridOptions: {
                  ...gridOptions,
                  currPage: currPage
                }
            }, () => {
                this.bringLogData(currPage, true, filterSuccess, filterWarning, filterError);
            });
        }
        
    }

    handleApplyFilterSuccess = (event) => {
        const { checked } = event.target;
        this.setState(
          { filterSuccess: checked },
          () => this.bringLogData(0, true)
        );
    };
      
    handleApplyFilterWarning = (event) => {
        const { checked } = event.target;
        this.setState(
          { filterWarning: checked },
          () => this.bringLogData(0, true)
        );
    };
      
    handleApplyFilterError = (event) => {
        const { checked } = event.target;
        this.setState(
          { filterError: checked },
          () => this.bringLogData(0, true)
        );
    };      
      
    removeFilters = () => {
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().replace("Z", "999Z");
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(currentDate.getDate() - 2);
        sevenDaysAgo.setHours(0, 0, 0, 0); // Definindo as horas para 00:00
        const formattedSevenDaysAgo = sevenDaysAgo.toISOString().replace("Z", "999Z");

        this.setState(
          {
            filterSuccess: true,
            filterWarning: true,
            filterError: true,
            withFilterDate: false,
            objectFilter: {
              filterStart: formattedSevenDaysAgo,
              filterEnd: formattedCurrentDate
            }
          },
          () => {
            this.findPage(0);
            this.bringLogData(0, false);
            }
        );
    };

    applyFilter = () => {
        this.setState({ 
            withFilterDate: true     
        }, () => {
            this.bringLogData(0, true)
        })
    }

    handleOpenAuditLog = () => {
        this.setState({ dialogOpenAuditLog: true });
    }

    handleDismissAuditLog = () => {
        this.setState({dialogOpenAuditLog: false});
    }

    render() {
        const {
            dataSource,
            gridOptions,
            loading,
            objectFilter,
            filtered,
            dialogOpenAuditLog,
            interfaceID,
            filterSuccess,
            filterWarning,
            filterError
        } = this.state;

        if (!gridOptions.hasOwnProperty("noDataMessage")) {
            setTimeout(() => this.setState({
                gridOptions: {
                    ...gridOptions,
                    noDataMessage: Translate("select_interface_execute_log")
                }
            }), 0);
        }

        return (
            <Fragment>
                <InternalPageTitle title={Translate("interface_log")} />
                <StyledPaper>
                    <Grid spacing={12} container alignItems={"center"}>
                        <Grid item xs={6} md={4}>
                            <ConectorDatePicker
                                value={objectFilter.filterStart}
                                required={true}
                                style={{zIndex: 3}}
                                onChange={this.handleChangeDate.bind(this)}
                                showTime={true}
                                name="filterStart"
                                label="filter_start"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                        <ConectorDatePicker
                                value={objectFilter.filterEnd}
                                required={true}
                                onChange={this.handleChangeDate.bind(this)}
                                showTime={true}
                                name="filterEnd"
                                label="filter_end"
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>                            
                                <StyledButton position = "absolute"
                                className="no-margin"
                                variant="contained"
                                color="primary"
                                style={{ marginRight: "90px", width: "140px" }}
                                onClick={this.removeFilters}
                            >
                                {Translate("remove_filter")}
                                </StyledButton>                                
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <StyledButton position = "absolute"
                                color="primary"
                                variant="contained"
                                style={{ width: '144px' }}
                                onClick={this.handleOpenAuditLog}
                            >
                                {Translate("summarry-logs")}
                            </StyledButton>
                        </Grid>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={filterSuccess} onChange={this.handleApplyFilterSuccess}
                                size="small" 
                                color="primary" 
                                />}

                                label={Translate("successes")}
                                labelPlacement="start"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={filterWarning} onChange={this.handleApplyFilterWarning} 
                                size="small" 
                                color="primary" 
                                />}

                                label={Translate("warnings")}
                                labelPlacement="start" 
                            />
                        </FormGroup>
                        <FormGroup>     
                            <FormControlLabel
                                control={<Switch checked={filterError} onChange={this.handleApplyFilterError} 
                                size="small" 
                                color="primary" 
                                />}

                                label={Translate("error")}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <StyledGrid item={true} xl={12} lg={12} md={12} sm={12}>
                            <ConectorExpandableTable dataSource={dataSource} gridOptions={gridOptions} />
                            {loading ? <Loading /> : ""}
                        </StyledGrid>
                    </Grid>
                </StyledPaper>
                {
                    (() => {
                        if (dialogOpenAuditLog) {
                            return <InterfacesAuditLogs id={interfaceID} objectFilter={objectFilter} onDismiss={this.handleDismissAuditLog.bind(this)} />
                        }
                    })()
                }
            </Fragment>
        )
    }
}

InterfacesLogs.defaultProps = {
    loading: true,
    dataSource: [],
    gridOptions: {
        currPage: 0,
        pageSize: 10,
        multiSelect: false,
        sortable: false,
        columns: [
            {
                title: "interface_mode",
                field: "interfaceModeId",
                cellFilter: "interfaceMode"
            },
            {
                title: "execution_date",
                field: "logDate",
                cellFilter: "ddMMyyyy-hh:mm"
            },
            {
                title: "details",
                field: "detailsCount"
            }
        ]
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

InterfacesLogs = connect(null, mapDispatchToProps)(InterfacesLogs)
export default InterfacesLogs