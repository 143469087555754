import React, { Component } from "react";
import { TextDescription, PanelTitle } from "../version-modal-styles";
import Translate from "../../../../i18n/translate";
import StyledButton from "../../../conector-ui/conector-styled-components/styled-button";
import StyledInputLabel from '../../../conector-ui/conector-styled-components/styled-input-label'
import ConectorInputText from "../../../conector-ui/conector-input-text";
import {
    DialogContent,
    Grid,
    DialogActions,
    Tooltip
} from "@material-ui/core";
import {
    MdEdit,
    MdDelete,
    MdCloudDownload
} from "react-icons/md";
import {
    StyledDialog,
    StyledDialogTitle
} from "../../../conector-ui/conector-styled-components/styled-dialog";
import StyledIconButton from '../../../conector-ui/conector-styled-components/styled-icon-button'
import ConectorDialogConfirmation from "../../../conector-ui/conector-dialog-confirmation";
import version from "../generic/version";
import UserService from "../../../../routes/users/user-service";
import Session from "../../../../utils/session-info";

class Version extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasPrivilege:false,
            infos: [],
            isEditing: null,
            url:"",
            newTitle: "",
            newNotes: []
        };
    }

    async componentWillMount() {
        const { system } = this.props;
        const Infos = await version.getPathNotes(system);
        //privilegio 5 = editar path_notes
        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 5);
        this.setState({ infos:Infos,hasPrivilege});
        
    }
    handleEdit = (index) => {
        const { infos } = this.state;
        this.setState({
            isEditing: index,
            newTitle: infos[index].version,
            url: infos[index].url,
            newNotes: infos[index].notes.map(note => note.note)
        });
    }

    handleChangeTitle = (event) => {
        this.setState({ newTitle: event.target.value });
    }
    handleChangeUrl = (event) => {
        this.setState({ url: event.target.value });
    }

    handleChangeNote = (event, index) => {
        const newNotes = [...this.state.newNotes];
        newNotes[index] = event.target.value;
        this.setState({ newNotes });
    }

    handleAddNote = () => {
        this.setState((prevState) => ({
            newNotes: [...prevState.newNotes, ""]
        }));
    }

    handleDeleteNote = (index) => {
        const newNotes = [...this.state.newNotes];
        newNotes.splice(index, 1);
        this.setState({ newNotes });
    }
    dismissModal() {
		this.setState({ confirmationModalOptions: { open: false } });
	}
    handleDeleteVersionModal = async (id) => {
        this.setState({
            confirmationModalOptions: {
                type: "danger",
                message: "remove_records",
                title: "deleteConfirmation",
                open: true,
                onDismiss:() => this.dismissModal,
                onClose: () => this.DeleteVersionConfirm(id) 
            }
        });
    }

    DeleteVersionConfirm = async (id) => {
        const { system } = this.props;
        
        await version.deletePathNotes(id);
        const Infos = await version.getPathNotes(system);
        this.setState({
            confirmationModalOptions: { open: false } ,
            infos: Infos,
            isEditing: null,
            newTitle: "",
            newNotes: []
        });
    }

    handleSave = async (index) => {
        const { newTitle, newNotes, infos, url } = this.state;
        const { system } = this.props;
        const filteredNotes = newNotes.filter(note => note.trim() !== "");
    
        if (newTitle.trim() === "" || filteredNotes.length === 0) {
            return;
        }
    
        const updatedInfos = [...infos];
        updatedInfos[index] = {
            ...updatedInfos[index],
            id_path: updatedInfos[index].id_path || 0,
            version: newTitle,
            system:system,
            url: url,
            notes: filteredNotes.map((note, i) => ({
                id_note: i < (updatedInfos[index].notes ? updatedInfos[index].notes.length : 0) ? updatedInfos[index].notes[i].id_note : 0,
                id_path: updatedInfos[index].id_path || 0,
                note: note
            }))
        };
    
        try {
            await version.addOrEditVersion(updatedInfos[index]);
            const Infos = await version.getPathNotes(system);
            this.setState({
                infos: Infos,
                isEditing: null,
                url: "",
                newTitle: "",
                newNotes: []
            });
        } catch (error) {
            console.error("Erro ao salvar as alterações:", error);
        }
    }

    getTitle = (system) => {
        switch (system) {
            case "Portal":
                return "historic_version_portal"
            case "Worker":
                return "historic_version_worker"
            case "MicroBack":
                return "historic_version_microback"
            case "MicroFront":
                return "historic_version_microfront"
            case "MicroDash":
                return "historic_version_microdash"
            default:
                break;
        }
    }

    handleAddVersion = () => {
        const { infos } = this.state;
    
        const newVersion = {
            id_path: 0, 
            version: "",
            url: "",
            notes: [{ id_note: 0, note: "" }] 
        };
    
        this.setState({
            infos: [newVersion, ...infos],
            isEditing: 0,
            url: "",
            newTitle: "",
            newNotes: [""]
        });
    }

    renderVersion = () => {
        const { infos, isEditing, newTitle, newNotes,url,hasPrivilege } = this.state;

        return infos.map((i, index) => (
            <div key={index.toString()}>
                {isEditing === index ? (
                    <div> 
                        <DialogContent>
                            <ConectorInputText
                                label={"Versão"}
                                name={"version"}
                                type={"text"}
                                value={newTitle}
                                onChange={this.handleChangeTitle}
                            />
                            <ConectorInputText
                                label={"Documentação (URL)"}
                                name={"url"}
                                type={"text"}
                                value={url}
                                onChange={this.handleChangeUrl}
                            />
                            <StyledInputLabel>
                                Notas
                            </StyledInputLabel>
                            {newNotes.map((note, i) => (
                                <Grid container spacing={12} key={i}>
                                    <Grid item sm={11}>
                                        <ConectorInputText
                                            name={"note" + i}
                                            type={"text"}
                                            value={note}
                                            onChange={(event) => this.handleChangeNote(event, i)}
                                        />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Tooltip title={Translate("remove")} placement="top">
                                            <span>
                                                <StyledIconButton
                                                    onClick={() => this.handleDeleteNote(i)}
                                                >
                                                    <MdDelete className={"default"} />
                                                </StyledIconButton>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            ))}
                            <br></br>
                            <StyledButton onClick={this.handleAddNote}>
                                Adicionar Nota
                            </StyledButton>
                            <StyledButton onClick={() => this.handleSave(index)}>
                                Salvar
                            </StyledButton>
                        </DialogContent>
                    </div>
                ) : (
                    <div>
                        <Grid container>
                            <PanelTitle>{'v' + i.version}</PanelTitle>
                            {i.url != null && (
                                <Tooltip title={"Documentação"}>
                                    <StyledIconButton
                                        style={{marginTop: -10}}
                                        onClick={() => window.open(i.url, '_blank')}
                                    >
                                        <MdCloudDownload fontSize="small"/>
                                    </StyledIconButton>
                                </Tooltip>
                            )}
                            {hasPrivilege && (
                                <>
                                    <Tooltip title={Translate("edit")}>
                                        <StyledIconButton
                                            style={{marginTop: -10}}
                                            disabled={isEditing != null}
                                            onClick={() => this.handleEdit(index)}
                                        >
                                            <MdEdit fontSize="small"/>
                                        </StyledIconButton>
                                    </Tooltip>
                                    <Tooltip title={Translate("remove")}>
                                        <span>
                                            <StyledIconButton
                                                style={{marginTop: -5, marginLeft: -10}}
                                                disabled={isEditing != null}
                                                onClick={() => this.handleDeleteVersionModal(i.id_path)}
                                            >
                                                <MdDelete fontSize="small"/>
                                            </StyledIconButton>
                                        </span>
                                    </Tooltip>
                                </>
                            )}
                        </Grid>
                        <TextDescription>
                            {i.notes.map((note, i) => (
                                <li key={i}>{note.note}</li>
                            ))}
                        </TextDescription>
                    </div>
                )}
            </div>
        ));
    };

    render() {
        const { isEditing,confirmationModalOptions,hasPrivilege } = this.state;
        const { system } = this.props;
        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <StyledDialogTitle className="info">
                    <Grid container >
                        <Grid item sm={9}>
                            {Translate(this.getTitle(system))}
                        </Grid>
                        {hasPrivilege &&
                            <Grid item sm={3}>
                                <StyledButton onClick={this.handleAddVersion} disabled={isEditing != null}>
                                    Adicionar
                                </StyledButton>
                            </Grid>
                        }
                    </Grid>
                </StyledDialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={24} sm={24} md={24} lg={12} xl={12}>
                            {this.renderVersion()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
                    <ConectorDialogConfirmation
                    dialogOptions={confirmationModalOptions}
                />
            </StyledDialog>
        );
    }
}

export default Version;
