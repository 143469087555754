import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux";
import SortableTree, {
    insertNode,
    getNodeAtPath,
    changeNodeAtPath,
    removeNodeAtPath
} from "react-sortable-tree";
import styled from "styled-components";

import {
    IconButton,
    Grid
} from "@material-ui/core";
import {
    MdDelete,
    MdAddCircle,
    MdEdit,
    MdCheckCircle,
    MdCancel
} from "react-icons/md";
import { darken } from "@material-ui/core/styles/colorManipulator";

import * as inputSelector from "../../../../store/interfaces/reducer";
import * as wizardStore from "../../../../store/wizard/wizard-store-reducer";

import Uuid from "../../../../utils/uuid-utils";

import ConectorSelect from "../../../../components/conector-ui/conector-select";
import ConectorInputText from "../../../../components/conector-ui/conector-input-text";
import StyledButton from "../../../../components/conector-ui/conector-styled-components/styled-button";

import Translate from "../../../../i18n/translate";
import Toast from "../../../../components/toast/toast";
import WizardService from "../../wizard-service";
import { getFlatDataFromTree } from "react-sortable-tree";

const InlineIconButton = styled(IconButton)`
    && {
        padding: 6px 6px 6px 3px;
    }
`

const StyledSortableTree = styled(SortableTree)`
    && {
        background-color: #fff;
        border: 1px solid #B8C6D6;
        border-radius: 4px;
        padding-right: 8px;
        > div { height: auto !important; }
        &.rst__tree > div > div.ReactVirtualized__Grid.ReactVirtualized__List { 
            height: auto !important;
            min-height: calc(100vh - 690px);
            ::-webkit-scrollbar { height: 8px; }
            ::-webkit-scrollbar-track { 
                -webkit-border-radius: 4px; 
                background: #f5f5f5; 
                border-radius: 4px; 
            }
            ::-webkit-scrollbar-thumb { 
                -webkit-border-radius: 4px; 
                border-radius: 4px; 
                background: #009cdf;
            }
            ::-webkit-scrollbar-thumb:window-inactive { background-color: #009cdf; }
        }

        .rst__moveHandle { 
            border: 0;
            box-shadow: 0 0;
            background-color: #B8C6D6;
            border-radius: 2px 0 0 2px;
            &:hover { background-color: ${darken("#B8C6D6", 0.2)}; }
        }

        .rst__rowContents { 
            min-width: 30px;
            box-shadow: 0 0;
            border: 1px solid #B8C6D6;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            &:hover { background-color: #f5f5f5; }
        }

        .rst__node { height: 59px !important; }
        .rst__collapseButton, .rst__expandButton { box-shadow: 0 0 0 1px #009CDF; }
        .rst__rowLabel { padding-right: 10px; color: #4C637B; }
        .rst__lineBlock, .rst__lineChildren { &::after, &::before { background-color: #B8C6D6; } }
        .rst__toolbarButton {
            button { 
                color: #6F8296;
                &:hover {
                    color: #009CDF;
                    background-color: transparent;    
                }
            }
        }

        .rst__rowTitle {
            font-size: 12px;
            font-weight: normal;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }

        .rst__row.selected {
            .rst__rowContents {
                cursor: default;
                background-color: #4C637B;
                .rst__rowLabel { color: #fff; }
                .rst__toolbarButton {
                    button { 
                        color: #fff;
                        &:hover { color: #fff; }
                    }
                }
            }
        }
    }
`

class WizardStructureDetailsTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    componentWillReceiveProps = props => {
        this.setState({ ...props }, () => {
            let { treeData } = this.state;

            if (treeData && treeData.length) {
                if (treeData[0].firstSelectAfterInputChange) {
                    setTimeout(() => {
                        delete treeData[0].firstSelectAfterInputChange;
                        document.getElementsByClassName("rst__rowContents")[0].click()
                        this.setState({ treeData })
                    })
                }
            }
        });
    }

    onChangeTree = treeData => {
        this.props.update({ ...this.state, treeData })
    }

    editLevelTitle = item => {
        item.node.isEditing = !Boolean(item.node.isEditing)
        item.node.tempTitle = item.node.title;
        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        })
    }

    actOnNewTitle = (item, persistTitle) => {
        if (persistTitle) {
            if(item.parentNode != null){
                if(item.parentNode.children.some(child => child.title === item.node.tempTitle.trim()) && item.node.title != item.node.tempTitle.trim()){
                    Toast.error(Translate("FieldAlreadyExists"))
                    return;
                }
            }
            item.node.title = item.node.tempTitle.trim();

            this.state.setChangedDetail("CHANGED", item.node);
        }

        item.node.isEditing = false;
        delete item.node.tempTitle;

        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    getLevelButtons = item => {
        if (item.node.isEditing) {
            return [(
                <InlineIconButton onClick={() => this.actOnNewTitle(item, true)}>
                    <MdCheckCircle size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.actOnNewTitle(item)}>
                    <MdCancel size={20} />
                </InlineIconButton>
            )];
        } else {
            return [(
                <InlineIconButton onClick={() => this.editLevelTitle(item)}>
                    <MdEdit size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.addLevel(item)}>
                    <MdAddCircle size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.removeLevel(item)}>
                    <MdDelete size={20} />
                </InlineIconButton>
            )];
        }

    }

    addLevel = node => {
        const newNode = {
            title: `Sub detail - ${node.node.children.length + 1}`,
            children: []
        }

        this.props.update({
            treeData: insertNode({
                treeData: this.state.treeData,
                newNode,
                minimumTreeIndex: node.treeIndex + 1,
                depth: node.path[node.path.length - 1] + 1 || 1,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex
                },
                expandParent: true
            }).treeData
        });

    }

    removeLevel = node => {
        let isSelected = node.node.uuid === this.state.selectedNodeId

        if (!isSelected && node.node.children) {
            isSelected = !!(node.node.children.find(child => {
                return child.uuid === this.state.selectedNodeId
            }))
        }

        this.state.setChangedDetail("DELETED", node.node);

        this.props.update({
            selectedNode: isSelected ? null : this.state.selectedNode,
            selectedNodeId: isSelected ? null : this.state.selectedNodeId,
            treeData: removeNodeAtPath({
                treeData: this.state.treeData,
                path: node.path,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex
                }
            })
        })

        this.setDeletedExtraInWizardStateEvent(node.node)
    }

    setDeletedExtraInWizardStateEvent = structureDetail => {
        const { wizardState } = this.state

        if(structureDetail.structureExtras != undefined && structureDetail.structureExtras.length > 0){
            structureDetail.structureExtras.map(extra => {
                wizardState.event.deletedExtras.push(extra.codStructureExtraValues)
            })
        }

        if(structureDetail.children != undefined && structureDetail.children.length > 0){
            structureDetail.children.map(childrenStructureDetail => {
                if(childrenStructureDetail.structureExtras != undefined && childrenStructureDetail.structureExtras.length > 0){
                    childrenStructureDetail.structureExtras.map(extra => {
                        wizardState.event.deletedExtras.push(extra.codStructureExtraValues)
                    })
                }
            })
        }
    }
    
    addDetail = _ => {
        this.props.update({
            treeData: this.state.treeData.concat([{
                title: `Detail da Estrutura - ${this.state.treeData.length + 1}`,
                children: []
            }])
        })
    }

    getNode = nodePath => {
        return getNodeAtPath({
            treeData: this.state.treeData,
            path: nodePath,
            getNodeKey: ({ treeIndex }) => {
                return treeIndex
            }
        })
    }

    selectRow = event => {

        let tagsThatDontSelect = ["path", "input", "svg", "button"];
        let element = event.target;

        if (tagsThatDontSelect.includes(
            element.tagName.toLowerCase()) || element.className === "rst__toolbarButton"
        ) {
            return;
        }

        while (!element.id) {
            element = element.parentElement;
        }

        const selectedNodePath = element["attributes"]["nodepath"]["value"]
            .split("-")
            .map(e => parseInt(e));

        let item = this.getNode(selectedNodePath);

        item.node.uuid = element.id;

        this.props.update({
            selectedNode: item,
            selectedNodeId: element.id,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: selectedNodePath,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    updateTempTitle = (event, item) => {

        item.node.tempTitle = event.target.value;

        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    nodeProps = item => {
        const { selectedNodeId, structureIn } = this.state;
        const id = item.node.uuid || Uuid.generateUuid();

        var title;
        if (item.node.isEditing) {
            title = (
                <input
                    value={item.node.tempTitle}
                    onChange={e => { this.updateTempTitle(e, item) }}
                />
            );
        }

        //se title é undefined, vai usar item.node.title
        return {
            buttons: this.getLevelButtons(item, structureIn),
            onClick: this.selectRow.bind(this),
            nodepath: item.path.join("-"),
            className: id === selectedNodeId ? "selected" : " ",
            title,
            id
        };
    }

    handleChangeInputSelect = e => {
        var { structureInInput, stepInputs } = this.state;

        if (structureInInput && e.codStructureDetail === structureInInput.idStructureDetail) {
            return;
        }

        structureInInput = stepInputs.find(option => e.codStructureDetail === option.idStructureDetail);

        this.props.update({ structureInInput });
    }

    handleChangeInputText = e => {
        let { stepStructure } = this.state
        stepStructure.desOutputDetails = e.target.value
        
        if(stepStructure.structureDetails && stepStructure.structureDetails.length)
        stepStructure.structureDetails[0].output = e.target.value 

        this.setState({stepStructure})
        this.props.renderEvent()
    }

    render() {
        const {
            treeHeight,
            treeData,
            structureIn,
            structureInInput,
            structureInputOptions,
            stepStructure
        } = this.state

        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={12} md={8} lg={12} xl={8}>
                        {
                            structureIn
                                ?
                                (
                                    <ConectorSelect
                                        name={"model-select"}
                                        label={"input_model"}
                                        placeholder={"choose"}
                                        options={structureInputOptions}
                                        value={
                                            structureInInput
                                                ? structureInputOptions.find(option =>
                                                    option.codStructureDetail === structureInInput.idStructureDetail
                                                )
                                                : null
                                        }
                                        onChange={this.handleChangeInputSelect.bind(this)}
                                    />
                                )
                                :
                                (
                                    <ConectorInputText
                                        name="detail-name"
                                        label="output_model"
                                        placeholder="enter_name"
                                        required={false}
                                        value={stepStructure.desOutputDetails}
                                        onChange={this.handleChangeInputText.bind(this)}
                                    />
                                )
                        }
                    </Grid>

                    <Grid item xs={12}>
                        <StyledButton
                            variant="contained"
                            size="small"
                            name={"add-detail"}
                            onClick={this.addDetail.bind(this)}
                        >
                            <MdAddCircle size={24} /> {Translate("add_detail")}
                        </StyledButton>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledSortableTree
                            generateNodeProps={this.nodeProps}
                            style={{
                                minHeight: treeHeight
                            }}
                            treeData={treeData}
                            onChange={this.onChangeTree.bind(this)}
                            isVirtualized={false}
                        />
                    </Grid>

                </Grid>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getEvents(store);

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        connectionActions: wizardStore.getConnectionActions(store)
    };
};

export default connect(mapStateToProps)(WizardStructureDetailsTree);