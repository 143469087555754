import "regenerator-runtime";

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Grid, Typography, IconButton, CardHeader, Avatar } from "@material-ui/core";
import Translate from "../../i18n/translate";
import { setBreadcrumb } from "../../store/app-state/actions";
import Toast from "../../components/toast/toast";
import ConectorSelect from "../../components/conector-ui/conector-select";
import ConectorCheckbox from "../../components/conector-ui/conector-checkbox";
import ConectorInputText from "../../components/conector-ui/conector-input-text";
import ConectorInputPassword from "../../components/conector-ui/conector-input-password";
import InternalPageTitle from "../../components/conector-ui/conector-internal-page-title";
import StyledPaper from "../../components/conector-ui/conector-styled-components/styled-paper";
import StyledButton from "../../components/conector-ui/conector-styled-components/styled-button";
import { StyledAvatar } from "./my-account-style";
import Session from "../../utils/session-info";
import request from "../../utils/request";
import validator from 'validator'

class MyAccount extends Component {

    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "my_account"
        }]);

        const session = Session();

        this.state = {
            user: {
                id: session.codUser,
                name: session.desName,
                hash: session.hash,
                avatar: session.avatar,
                changePassword: false,
                language: session.language
            },
            errors: [],
            passwordVisible: false
        };
    }

    handleChange(event) {
        const target = event.target;
        const property = target.name;
        let { user } = this.state;
        let { changePassword, password, passwordConfirmed } = this.state.user;
        let value = target[(target.type == "checkbox" ? "checked" : "value")];
        user[property] = value;

        if(target.name != undefined)
            switch (target.name) 
            {
                case "password":
                    password = target.value
                    break;
                case "passwordConfirmed":
                    passwordConfirmed = target.value
                    break;
                case "changePassword":
                    this.state.user.password = ""
                    this.state.user.passwordConfirmed = ""
            }

        this.saveDisabled()
        this.setState({"user": user});
    }

    handleChangeLanguage(language) {
        this.handleChange({
            target: {
                name: "language",
                value: language.value
            }
        });
    }

    uploadAvatarFinish(inputAvatar) {
        setTimeout(function () {
            let { user } = this.state;
            user.avatar = inputAvatar.previousSibling.src;

            this.setState({
                "user": user
            });
        }.bind(this), 100);
    }

    switchPasswordVisibility() {
        this.setState({
            "passwordVisible": !this.state.passwordVisible
        });
    }

    copyHash() {
        const inputAux = document.createElement("input");
        inputAux.value = this.state.user.hash;
        document.body.appendChild(inputAux);

        inputAux.select();

        document.execCommand("copy");
        document.body.removeChild(inputAux);
    }

    save() {
        this.validateForm();

        if (this.state.errors.length == 0) {
            const { user } = this.state;

            request.executeRequest(
                `${request.baseUrl}globals/edit-user`,
                {
                    ...request.postOptions(),
                    body: JSON.stringify({
                        codUser: user.id,
                        avatar: user.avatar,
                        desName: user.name,
                        language: user.language,
                        desPassword: user.changePassword ? user.password : null
                    })
                }
            ).then((response) => {
                switch(response.status) {
                    case 200:
                        this.updateSession();
                        this.resetPassword();
                        Toast.success(Translate("user_success_changed"));
                    break;
                    case 207:
                        Toast.error('same_pass')
                      break;
                    case 423:
                        Toast.error(Translate("time_edit"))
                      break;
                    case 401:
                        Toast.error(Translate("password_used_recently"));
                      break;
                    default:
                        Toast.error(Translate("internal_error"))
                    break;
                }
            })
        }
    }

    validateForm() {
        let { user, errors } = this.state;

        this.validateUserName(user.name, errors);
        this.validateUserPassword(user, errors);

        this.setState({
            "errors": errors
        });
    }

    validateUserName(name, errors) {
        this.removeError(errors, "name");

        if (this.isBlankOrNull(name)) {
            errors.push("name");
        }
    }

    validateUserPassword(user, errors) {
        this.removeError(errors, "password");
        this.removeError(errors, "passwordConfirmed");
        this.removeError(errors, "passwordStrength");

        if (user.changePassword) {
            const hasPassword = !this.isBlankOrNull(user.password);
            const hasPasswordConfirmed = !this.isBlankOrNull(user.passwordConfirmed);

            if (!hasPassword && !hasPasswordConfirmed) {
                errors.push("password");
                errors.push("passwordConfirmed");
            } else if (!hasPassword) {
                errors.push("password");
            } else if (!hasPasswordConfirmed || user.password != user.passwordConfirmed) {
                errors.push("passwordConfirmed");
                Toast.error("Passwords_Not_Match");
            }
            if(!this.validatePasswordStrength(user.password)){
                errors.push("passwordStrength");
                Toast.error("password_not_strong");
            }
        }
    }

    validatePasswordStrength = (password) => {
		var allowedToSave = false
  
		if (validator.isStrongPassword(password, {
		  minLength: 8, minLowercase: 1,
		  minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
		  allowedToSave = true
		}

		return allowedToSave
	}

    isBlankOrNull(value) {
        return !value || value.trim() == "";
    }

    removeError(errors, error) {
        const errorIndex = errors.indexOf(error);

        if (errorIndex >= 0) {
            errors.splice(errorIndex, 1);
        }
    }

    updateSession() {
        const { user } = this.state;

        const session = Session();
        session.avatar = user.avatar;
        session.desName = user.name;
        session.language = user.language;

        localStorage.setItem("user", JSON.stringify(session));
    }

    resetPassword() {
        let { user } = this.state;
        user.changePassword = false;
        user.password = "";
        user.passwordConfirmed = "";

        this.setState({
            "user": user,
            "passwordVisible": false
        });
    }

    getLanguages() {
        return [{
            value: "en-US",
            label: "Inglês"
        }, {
            value: "pt-BR",
            label: "Português"
        }];
    }

    saveDisabled = () => {
        const { changePassword, password, passwordConfirmed } = this.state.user;

        if(changePassword)
            return password != undefined && 
            password != "" &&
            passwordConfirmed != undefined && 
            passwordConfirmed != "" ?
            false : true
        else
            return false;
    }

    render() {
        const {
            user,
            errors,
            passwordVisible
        } = this.state;

        const languages = this.getLanguages();

        return (
            <Fragment>
                <InternalPageTitle title={Translate("my_account")} />

                <StyledPaper>
                    <Grid container={true} spacing={24}>
                        <Grid item xs={12}>
                            <StyledPaper className={"bg_darken_04"}>
                                <Grid container={true} spacing={24} wrap="nowrap" alignItems="center">
                                    <Grid item xs>
                                        <StyledAvatar>                                            
                                            <CardHeader avatar={
                                        <Avatar aria-label="Recipe" style={{"width": "150px", "height": "150px"}}>
                                            <img  style={{"width": "100%"}} src={ "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyBjbGFzcz0iaWNvbiIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNTEyIDI0LjM4MDk1MkMyNDIuNTkwNDc2IDI0LjM4MDk1MiAyNC4zODA5NTIgMjQyLjU5MDQ3NiAyNC4zODA5NTIgNTEyczIxOC4yMDk1MjQgNDg3LjYxOTA0OCA0ODcuNjE5MDQ4IDQ4Ny42MTkwNDggNDg3LjYxOTA0OC0yMTguMjA5NTI0IDQ4Ny42MTkwNDgtNDg3LjYxOTA0OFM3ODEuNDA5NTI0IDI0LjM4MDk1MiA1MTIgMjQuMzgwOTUyeiBtLTcuMzE0Mjg2IDE3MC42NjY2NjdjOTAuMjA5NTI0IDAgMTY0LjU3MTQyOSA3MC43MDQ3NjIgMTY0LjU3MTQyOSAxNTguNDc2MTkxcy03My4xNDI4NTcgMTU4LjQ3NjE5LTE2NC41NzE0MjkgMTU4LjQ3NjE5QzQxNC40NzYxOSA1MTIgMzQxLjMzMzMzMyA0NDEuMjk1MjM4IDM0MS4zMzMzMzMgMzUzLjUyMzgxczczLjE0Mjg1Ny0xNTguNDc2MTkgMTYzLjM1MjM4MS0xNTguNDc2MTkxek03OTIuMzgwOTUyIDc4Mi42Mjg1NzFjMCA0NS4xMDQ3NjItOTUuMDg1NzE0IDQ2LjMyMzgxLTIxMi4xMTQyODUgNDYuMzIzODFINDQzLjczMzMzM0MzMjYuNzA0NzYyIDgyOC45NTIzODEgMjMxLjYxOTA0OCA4MjguOTUyMzgxIDIzMS42MTkwNDggNzgyLjYyODU3MXYtMTMuNDA5NTIzYzAtMTEzLjM3MTQyOSA5NS4wODU3MTQtMjA0LjggMjEyLjExNDI4NS0yMDQuOGgxMzYuNTMzMzM0YzExNy4wMjg1NzEgMCAyMTIuMTE0Mjg2IDkxLjQyODU3MSAyMTIuMTE0Mjg1IDIwNC44djEzLjQwOTUyM3oiIGZpbGw9IiM2NjY2NjYiIC8+PC9zdmc+"} />
                                        </Avatar>
                                    } style={{"padding-bottom": "0"}} />
                                        </StyledAvatar>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h4">
                                            {Translate("welcome") + (this.isBlankOrNull(user.name) ? "" : (", " + user.name)) + "."}
                                        </Typography>

                                        <Typography variant="subtitle1">{Translate("details_your_account")}</Typography>
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                        </Grid>
                    </Grid>

                    <Grid container={true} spacing={24}>
                        <Grid item xs={6}>
                            <ConectorInputText label={"name"} help={"enter_name"} name={"name"}
                                required={true} value={user.name}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={errors.find(error => error === "name")}
                            />
                        </Grid>
                    </Grid>

                    <Grid container={true} spacing={24}>
                        <Grid item xs={6}>
                            <ConectorSelect label={Translate("language")} name={"language"} options={languages}
                                onChange={this.handleChangeLanguage.bind(this)}
                                value={languages.find(language => language.value == user.language)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container={true} spacing={24}>
                        <Grid item xs={12}>
                            <ConectorCheckbox name="changePassword" color="default" value={user.changePassword}
                                onChange={this.handleChange.bind(this)} checked={user.changePassword}
                                label={Translate("change_password")}
                            />

                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <Grid item xs={5}>
                            <ConectorInputPassword label={Translate("password")} name={"password"}
                                required={user.changePassword} disabled={!user.changePassword} value={user.password}
                                type={passwordVisible} onChange={this.handleChange.bind(this)}
                                errorCondition={user.changePassword ? errors.find(error => error === "password") : null}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <ConectorInputPassword label={Translate("confirm_password")} name={"passwordConfirmed"}
                                required={user.changePassword} disabled={!user.changePassword} value={user.passwordConfirmed}
                                type={passwordVisible} onChange={this.handleChange.bind(this)}
                                errorCondition={user.changePassword ? errors.find(error => error === "passwordConfirmed") : null}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <IconButton style={{ "margin-top": "20px" }} onClick={this.switchPasswordVisibility.bind(this)}
                                disabled={!user.changePassword}>
                                {passwordVisible ? <MdVisibility/> : <MdVisibilityOff/>}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} xs={12}>
                            <ConectorInputText label={"hash"} name={"hash"} type={"text"}
                                disabled={true} value={user.hash} endIcon={"file_copy"}
                                endTooltip={"copy"} endActionButton={this.copyHash.bind(this)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container={true} spacing={24} justify="flex-end">
                        <Grid item>
                            <StyledButton 
                                className="no-margin" 
                                variant="contained" 
                                onClick={this.save.bind(this)}
                                disabled={this.saveDisabled()}>
                                {Translate("save")}
                            </StyledButton>
                        </Grid>
                    </Grid>
                </StyledPaper>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(MyAccount);