import React, {
    Component,
    Fragment
} from "react";
import {
    DialogActions,
    DialogContent
} from "@material-ui/core/";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";
import Translate from "../../../i18n/translate";
import InterfacesService from "../interfaces-service";
import moment from "moment";
import './historic-interface-status-style.css';
import {StatusItemError, StatusItemSuccess} from './historic-items-status-style';

class HistoricInterfaceStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            historicInterfaceStatus: new Array,
            loading: true,
        }
    }
    componentWillMount() {
        const { codInstanceInterface } = this.state.targetInterface;
        InterfacesService.GetHistoricInterfacesStatus(codInstanceInterface)
        .then(historicInterfaceStatus => this.setState({historicInterfaceStatus}))
        .finally(() => this.setState({loading: false}))
    }
    renderListHistoricInterfaceStatus(){
        const { loading, historicInterfaceStatus } = this.state

        const historicStatusList = historicInterfaceStatus.map(objItem =>{
            if(objItem.activated) {
                return <StatusItemSuccess>
                            <div>
                                <p>{`${moment(objItem.status_change_date).format("DD/MM/YYYY HH:mm:ss")} - ${objItem.des_name} ${Translate("startedExecution")}`}</p>
                            </div>
                        </StatusItemSuccess>
            }
            else if(objItem.cod_user == 0) {
                return <StatusItemError>
                            <div>
                                <p>{`${moment(objItem.status_change_date).format("DD/MM/YYYY HH:mm:ss")} - ${objItem.des_name} ${Translate("errorInterruptedExecution")}`}</p>
                            </div>
                        </StatusItemError>
            }
            else {
                return <StatusItemError>
                            <div>
                                <p>{`${moment(objItem.status_change_date).format("DD/MM/YYYY HH:mm:ss")} - ${objItem.des_name} ${Translate("stoppedExecution")}`}</p>
                            </div>
                        </StatusItemError>
            }
        })
        if(loading) return this.renderLoading()
        else return <ul class="historicStatusList_ul">{historicStatusList}</ul>
    }
    renderLoading = () => {return <div class="containerLoading"><div class="loading"><div></div><div></div><div></div><div></div></div></div>}
    render() {
        return (
            <div>
                <Fragment>
                    <DialogContent>
                        {this.renderListHistoricInterfaceStatus()}
                    </DialogContent>
                    <DialogActions>
                        <StyledButton variant="contained" color="primary" onClick={this.props.close.bind(this)}>
                            {Translate("close")}
                        </StyledButton>
                    </DialogActions>
                </Fragment>
            </div>
        )
    }
}
export default HistoricInterfaceStatus