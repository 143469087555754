import styled from "styled-components";
import ConnectorGridFilters from "../../../components/conector-ui/conector-grid/conector-grid-filters";
import Translate from "../../../i18n/translate";
import StackTraceModal from "./stack-trace-modal";
import ReactJson from 'react-json-view';
import xmlFormat from 'xml-formatter';

import React, {
    Component,
    Fragment
} from "react";

import {
    Table,
    TableFooter,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    IconButton
} from "@material-ui/core/";

import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdZoomIn
} from "react-icons/md";

const ExpandableTableRow = styled(TableRow)`
word-break: break-word;

    && {
        &.hidden {
            display: none;

        }

        td {
            padding: 0 40px;

            border-bottom: 1px solid #E0E0E0;
        }

        animation: slidein 0.25s;
        @keyframes slidein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }
`

const StyledGridContainer = styled.div`
    && {
        width: 100%;
        overflow: auto;
        &.scrollWidth { 
            width: calc(100%);
            hr { width: 100%; }

            &.scrollHeight {
                width: calc(100% - 7px);
                hr { width: 100%; }
                table { width: calc(100% - 7px); margin-right: 7px; }
            }
            :before {
                content: "";
                width: 1px;
                /* height: 100%; */
                right: 32px;
                z-index: 9999;
                position: absolute;
                background-color: #B8C6D6;
            }
        }

        &.scrollHeight {
            hr { width: calc(100% - 7px); }
            table { width: calc(100% - 7px); margin-right: 7px; }
        }

        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`

const StyledTextFragment = styled.div`
    && {
              
        > p  {
            margin-top: 4px;
            margin-bottom: 15px;
            font-family: "Roboto", "Helvetica", "Arial", "sans-serif";  
            word-break: break-word;
            white-space: normal;
            
        }

        > pre {
            font-family: "Roboto", "Helvetica", "Arial", "sans-serif"; 
        }
    }
`;

const StyledTableFooter = styled(Table)`
    && { 
        width: calc(100%);
        background: white;
        tr { border: 1px solid #B8C6D6; }

        &&.full-size-container {
            width: 100%;
            margin-right: 0;
        }
    }
`

const StyledTable = styled(Table)`
    && {

        tbody > tr.contentRow > td:not(:last-child):not(.checkbox) {
            padding: 10px 24px;
        }

        tbody > tr.contentRow > td:not(.checkbox) {
            padding-top: 10px;
            padding-bottom: 10px
        }

        tbody td.pointer { cursor: pointer; }

        tr {
            height: auto;
            border-right: 1px solid #B8C6D6;
            border-left: 1px solid #B8C6D6;

            th {
                color: #4c637b;
                padding: 10px 24px;
                background-color: #B8C6D6;
                text-transform: uppercase;
                span { color: #4c637b; }
            }
            
            td {
                position: relative;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #fff;
                line-height: 0;
                color: #6F8296;

                .MuiTableCell-paddingCheckbox { 
                    padding: 0 12px;
                    svg { color: #6F8296; } 
                }

                &.notFound {
                    text-align: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-weight: bold;
                }
            }

            &:last-child { td { border-bottom: 0; }}
            tbody tr { cursor: pointer; }
        }

        thead > tr > th {
            top: 0;
            z-index: 2;
            position: sticky;
        }

        thead > tr > th:first-child { width: 50px; }
        thead > tr > th:last-child { padding-right: 0px; }

        &&.full-size-container {
            width: 100%;
            margin-right: 0;
        }

        margin: auto;
        margin-right: 13px;
        width: calc(100%);
        border-collapse: collapse;
    }
`

const CustomListItem = styled.li`
    && {
        padding: 5px 0;
        display: inline;
        text-align: justify;
        line-height: 1;
        position: static;
        

        div:last-child {
            margin-bottom: 10px
            button {
                padding: 0;
           }
    }
    }
`

class ConectorExpandableTable extends Component {

    constructor(props) {
        super(props);

        var {
            gridOptions,
            dataSource
        } = props;

        gridOptions = {
            ...gridOptions,
            sortType: "asc"
        }

        this.state = {
            gridOptions,
            dataSource
        };
    }

    componentWillReceiveProps = ({ gridOptions, dataSource }) => {
        this.setState({
            gridOptions: {
                ...this.state.gridOptions,
                ...gridOptions
            },
            dataSource
        });
    }

    renderHeader(gridOptions) {
        let cellIndex = 0;

        const renderHeaderCells = () => {
            let cellsArr = [];

            cellsArr.push(
                <TableCell key={"icon"}>
                    <MdKeyboardArrowDown size={24} style={{ opacity: 0 }} />
                </TableCell>
            );

            gridOptions.columns.forEach(coluna => {
                cellIndex++;

                cellsArr.push(
                    <TableCell key={cellIndex}>
                        {coluna.title}
                    </TableCell>
                )
            });

            return cellsArr;
        }

        return (
            <TableHead>
                <TableRow key={0}>
                    {renderHeaderCells()}
                </TableRow>
            </TableHead>
        )
    }

    expandRow = idx => {
        var { dataSource } = this.state,
            row = dataSource.logs[idx];

        if (!row.detailsCount) {
            return;
        }

        row.expanded = !Boolean(row.expanded);
        this.setState({ dataSource });
    }

    getValue = (obj, path) => {
        var objPath = obj;

        if (!path) {
            return;
        }

        if (path instanceof Object) {
            path = path.field;
        }

        path.split(".").forEach(function (p) {
            if (objPath) {
                objPath = objPath[p];
            }
        });

        return objPath;
    }

    renderFooter(gridOptions, dataSource) {
        const { filterError, filterSuccess, filterWarning } = gridOptions;

        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        page={gridOptions.currPage}
                        rowsPerPage={gridOptions.pageSize}
                        onChangePage={(e, currPage) => {
                            gridOptions.findPage(currPage, filterError, filterSuccess, filterWarning);
                        }}
                        labelRowsPerPage={Translate("items_per_page")}
                        labelDisplayedRows={() => {
                            let labelTemplate = `${Translate("displaying_from")} [recordIndex] ${Translate("up_to")} [pageSize], ${Translate("from", true)} [total] ${Translate("records")}`;

                            if (gridOptions.currPage === 0) {
                                if (dataSource.logs && dataSource.logs.length) {
                                    labelTemplate = labelTemplate.replace("[recordIndex]", "1");
                                } else {
                                    labelTemplate = labelTemplate.replace("[recordIndex]", "0");
                                }
                            } else {
                                labelTemplate = labelTemplate.replace("[recordIndex]", ((gridOptions.currPage * gridOptions.pageSize) + 1));
                            }

                            if ((gridOptions.currPage + 1) * gridOptions.pageSize > dataSource.total) {
                                labelTemplate = labelTemplate.replace("[pageSize]", dataSource.total);
                            } else {
                                labelTemplate = labelTemplate.replace("[pageSize]", ((gridOptions.currPage + 1) * gridOptions.pageSize));
                            }

                            labelTemplate = labelTemplate.replace("[total]", dataSource.total);

                            return labelTemplate;
                        }}
                        rowsPerPageOptions={[]}
                        count={dataSource.total || 0}
                    ></TablePagination>
                </TableRow>
            </TableFooter>
        )
    }

    renderContent(gridOptions, dataSource) {
        if (dataSource.total == 0) {
            return (
                <TableBody>
                    <TableRow className={this.state.gridOptions.sortable ? "contentRow" : ""} key={"notFound"} >
                        <TableCell className={"notFound"} colSpan={4}>
                            {gridOptions.noDataMessage}
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }

        if (gridOptions.filter && gridOptions.filter.field) {
            dataSource.logs = dataSource.logs.filter(obj => {
                let value = gridOptions.filter.field.indexOf(".") !== -1
                    ? this.getValue(obj, gridOptions.filter.field)
                    : obj[gridOptions.filter.field]

                if (typeof (value) === "string") {
                    return value.toUpperCase().indexOf(gridOptions.filter.value.toUpperCase()) !== -1
                } else {
                    return value.toString().indexOf(gridOptions.filter.value) !== -1
                }
            })
        }

        let rowIndex = 0

        const criarRow = (row) => {
            rowIndex++
            let cellIndex = 0,
                cellsArr = [];

            cellsArr.push(
                <TableCell align="center" key={"icon"}>
                    {
                        row.expanded
                            ? <MdKeyboardArrowUp
                                size={24}
                                style={
                                !row.detailsCount
                                    ? { opacity: "0.5" }
                                    : {}
                                }
                            />
                            : <MdKeyboardArrowDown 
                                    size={24}
                                    style={
                                        !row.detailsCount
                                            ? { opacity: "0.5" }
                                            : {}
                                    }
                            />
                    }
                </TableCell>
            );

            gridOptions.columns.forEach(coluna => {
                let value = coluna.field.indexOf(".") !== -1
                    ? this.getValue(row, coluna.field)
                    : row[coluna.field]

                if (coluna.cellFilter) {
                    value = ConnectorGridFilters(value, coluna.cellFilter)
                }

                cellIndex++

                cellsArr.push(
                    <TableCell
                        title={value}
                        key={rowIndex + "+" + cellIndex}
                        className={!row.detailsCount ? "" : "pointer"}
                        style={{
                            textAlign: coluna.textAlign ? coluna.textAlign : "left",
                            minWidth: coluna.size ? coluna.size + "px" : "50px",
                            maxWidth: coluna.size ? coluna.size + "px" : "auto",
                            paddingLeft: "24px"
                        }}
                    >
                        {value}
                    </TableCell>
                )
            })

            row.idx = rowIndex - 1;
            ds.push(
                <TableRow
                    className={this.state.gridOptions.sortable ? "contentRow" : ""}
                    onClick={() => { this.expandRow(row.idx) }}
                    key={rowIndex}
                    hover={true}>
                    {cellsArr}
                </TableRow>
            );
            if(row.expanded){
            ds.push(
                <ExpandableTableRow key={`${rowIndex}-exp`}
                    className={!Boolean(row.expanded) ? "hidden" : "contentRow"}>
                    <td colspan="4">
                        <ul>
                            {row.logInterfaceDetails.map(detail => (
                                <CustomListItem>
                                    <strong>{detail.logType}</strong> - <strong>{detail.dateTime}</strong>
                                    <br />
                                    <StyledTextFragment>{this.generateLogText(detail.message)}</StyledTextFragment>
                                    <div style={detail.stackTrace.length
                                        ? {}
                                        : { display: "none" }
                                    }>
                                        <strong>{Translate("trace")}: </strong>
                                        {
                                            detail.stackTrace.length > 150
                                                ? `${detail.stackTrace.slice(0, 150)}...`
                                                : detail.stackTrace
                                        }
                                        <Tooltip title={Translate("view_full")}>
                                            <IconButton onClick={_ => { this.displayLog(detail.stackTrace) }}>
                                                <MdZoomIn/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </CustomListItem>
                            ))}
                        </ul>
                    </td>
                </ExpandableTableRow>
            );}
        }

        var ds = [];

        if (dataSource.logs) {
            dataSource.logs.map(criarRow.bind(this));
        }

        return (<TableBody>{ds}</TableBody>)
    }

    getSplitedMessage(message) {
        return message
            .split(/(.{50000})/)
            .filter(m => m)
            .map(fragment => {
                return <p>{fragment}</p>
            });
    }

    displayLog = trace => {
        this.setState({
            stackTraceToExpand: trace,
            stackTraceModalOpen: true
        });
    }

    onCloseStackTraceModal = _ => {
        this.setState({ stackTraceModalOpen: false });
    }

    generateLogText(message) {
            try {
                let JSONlog = JSON.parse(message);
                return <p><ReactJson src={JSONlog} collapsed={true} /></p>
            }
            catch (e) {
                try {
                    return <pre>{xmlFormat(message, {
                        indentation: '      ', 
                        filter: (node) => node.type !== 'Comment', 
                        collapseContent: true, 
                        lineSeparator: '\n'
                    })}</pre>
                }
                catch (e) {
                    return this.getSplitedMessage(message)
                }
            }
    }

    render() {

        const {
            dataSource,
            gridOptions,
            stackTraceModalOpen,
            stackTraceToExpand
        } = this.state;

        return (
            <Fragment>
                <StyledGridContainer>
                    <StyledTable>
                        {this.renderHeader(gridOptions)}
                        {this.renderContent(this.state.gridOptions, { ...dataSource })}
                    </StyledTable>
                </StyledGridContainer>
                <StyledTableFooter>
                    {this.renderFooter(this.state.gridOptions, this.state.dataSource)}
                </StyledTableFooter>
                <StackTraceModal open={stackTraceModalOpen || false}
                    stackTraceToExpand={stackTraceToExpand}
                    onClose={this.onCloseStackTraceModal}
                />
            </Fragment>
        );
    }
}

ConectorExpandableTable.defaultProps = {
    dataSource: {
        logs: [],
        total: 0
    },
    stackTraceModalOpen: false
};

export default ConectorExpandableTable;