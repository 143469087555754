import React, {
    Fragment,
    Component
} from "react";
import styled from "styled-components";

import {
    Grid,
    DialogActions,
    DialogContent
} from "@material-ui/core/";

import Loading from "../../../components/conector-ui/conector-loading";
import ConectorInputText from "../../../components/conector-ui/conector-input-text";
import ConectorInputFile from "../../../components/conector-ui/conector-input-file";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";

import Translate from "../../../i18n/translate";
import Session from "../../../utils/session-info";

import RepositoryService from "./interface-repository-service";

const StyledLoading = styled.div`

    && > div {
        height: 256px;
    }

`;

class InterfaceApproveForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isSaving: false,
            saveDisabled: true
        };
    }

    handleSave() {
        const { targetItem } = this.state;

        if (targetItem.document || targetItem.icon) {
            this.saveFiles();
        } else {
            this.saveRepositoryItem();
        }
    }

    saveFiles() {
        const { targetItem } = this.state;

        this.setState({
            isSaving: true
        }, () => {
            let data = new FormData();

            if (targetItem.document) {
                data.append("doc", targetItem.document[0]);
            }

            if (targetItem.icon) {
                data.append("icon", targetItem.icon[0]);
            }

            RepositoryService.Upload(targetItem.codData, data).then(({success, codData}) => {
                if (success) {
                    targetItem.codData = codData;

                    this.setState({
                        targetItem
                    }, this.saveRepositoryItem);
                } else {
                    this.setState({ isSaving: false });
                }
            });
        });
    }

    saveRepositoryItem() {
        const { targetItem } = this.state;

        this.setState({
            isSaving: true
        }, () => {
            if (targetItem.published) {
                RepositoryService.Update({
                    ...targetItem
                }).then(this.handleBeforeSaveRepositoryItem.bind(this));
            } else {
                RepositoryService.Approve({
                    ...targetItem,
                    publishedBy: {
                        Value: Session().codUser
                    }
                }).then(this.handleBeforeSaveRepositoryItem.bind(this));
            }
        });
    }

    handleBeforeSaveRepositoryItem(ok) {
        const {
            close
        } = this.state;

        if (ok) {
            close(this, true);
        } else {
            this.setState({
                isSaving: false
            });
        }
    }

    handleChange = ({ target: {name, value, files} }) => {
        let { targetItem } = this.state;

        this.setState({
            targetItem: {
                ...targetItem,
                [name]: files ? files : value
            }
        }, () => {
            this.setState({
                saveDisabled: !this.isFormValid()
            });
        });
    }

    isFormValid() {
        let {
            name,
            document,
            codData
        } = this.state.targetItem;

        let valid = true;

        if (name.trim() == "") {
            valid = false;
        } else if (!codData && (!document || document.length == 0)) {
            valid = false;
        }

        return valid;
    }

    render() {
        const {
            close,
            targetItem,
            isSaving,
            saveDisabled
        } = this.state;

        if (isSaving) {
            return (
                <StyledLoading>
                    <Loading />
                </StyledLoading>
            );
        }

        return (
            <Fragment>
                <DialogContent>
                    <Grid container={true} spacing={24}>
                        <Grid item={true} xl={12} lg={12} md={12} xs={12} sm={12}>
                            <ConectorInputText label={"name"} help={"enter_name"} name={"name"} 
                                value={targetItem.name} onChange={this.handleChange.bind(this)} required={true} />
                        </Grid>
                        <Grid item={true} xl={6}>
                            <ConectorInputFile id="interface-approve-file-document" label={"add_document"} name={"document"} 
                                value={targetItem.file} onChange={this.handleChange.bind(this)} required={true} accept="application/pdf" />
                        </Grid>
                        <Grid item={true} xl={6}>
                            <ConectorInputFile id="interface-approve-file-icon" label={"add_icon"} name={"icon"} 
                                value={targetItem.iconFile} onChange={this.handleChange.bind(this)} required={false} accept="image/*" />
                        </Grid>
                    </Grid >
                </DialogContent>
                <DialogActions>
                    <StyledButton variant="contained" color="primary" onClick={close.bind(this)}> {Translate("cancel")} </StyledButton>
                    <StyledButton variant="contained" disabled={saveDisabled} onClick={this.handleSave.bind(this)} >
                        {Translate(targetItem.published ? "save" : "approve")}
                    </StyledButton >
                </DialogActions>
            </Fragment>
        );
    }

}

export default InterfaceApproveForm;