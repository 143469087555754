import React, { Component } from "react";

import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import {
    DragDropContext,
    Droppable,
    Draggable
} from "react-beautiful-dnd";

import IconButton from "@material-ui/core/IconButton";
import { MdDelete, MdEdit, MdFileCopy } from "react-icons/md"

import Grid from "@material-ui/core/Grid";

import * as wizardStore from "../../../../store/wizard/wizard-store-reducer";
import * as interfaceSelector from "../../../../store/interfaces/reducer";
import { setInterfaceEvents } from "../../../../store/interfaces/actions"
import * as globalSelector from "../../../../store/globals/reducer";

import InterfacesEventsService from "../interfaces-events-service";

import {
    TimelineTitle,
    TimelineDiv,
    TimelineCard,
    TimelineIcon
} from "./events-timeline-styles";

import Translate from "../../../../i18n/translate";

class EventCard extends Component {
    constructor(props) {
        super(props);

        const {
            events,
            connectionActions,
            connectionModes,
            interfaceEvents,
            banksList,
            bankAccounts
        } = this.props;

        this.state = {
            events,
            interfaceEvents,
            connectionActions,
            connectionModes,
            banksList,
            bankAccounts
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd({ source, destination }) {
        let eventsOrder = {};
        let sourceIndex = source.index;
        let destIndex = (destination || {}).index;

        if (!destination || destIndex === sourceIndex) {
            return;
        }

        let { interfaceEvents } = this.props;

        interfaceEvents[sourceIndex].indPosition = (destIndex + 1);
        eventsOrder[interfaceEvents[sourceIndex].codInterfaceEvent] = (destIndex + 1);

        if (sourceIndex < destIndex) {
            for (let d = destIndex; d > sourceIndex; d--) {
                interfaceEvents[d].indPosition = d;
                eventsOrder[interfaceEvents[d].codInterfaceEvent] = d;
            }
        } else {
            for (let s = sourceIndex; s > destIndex; s--) {
                interfaceEvents[s - 1].indPosition = (s + 1);
                eventsOrder[interfaceEvents[s - 1].codInterfaceEvent] = (s + 1);
            }
        }

        interfaceEvents = interfaceEvents.sort((item1, item2) => item1.indPosition - item2.indPosition);

        this.props.setInterfaceEvents(interfaceEvents);
        InterfacesEventsService.UpdateEventsOrder(eventsOrder);
    }

    componentWillReceiveProps = ({
        connectionActions = [],
        interfaceEvents = [],
        events = [],
        connectionModes = [],
        banksList,
        bankAccounts,
        codInterface
    }) => {
        this.setState({
            interfaceEvents,
            events,
            codInterface,
            connectionModes,
            connectionActions,
            banksList,
            bankAccounts
        });
    }

    generateEventNameTitle(event){
        const { bankAccounts, banksList } = this.state
        let eventName = undefined

        switch (event.codEvent) {
            case 1: eventName = "connection"
            break;

            case 6:
                let bankStructures = event.structures[0].bankStructures

                if(bankStructures != undefined){
                    let idBankAccountFromStructure = bankStructures.idBankAccount
                    let codBankFromAccount = bankAccounts.filter(account => account.id_account == idBankAccountFromStructure)[0].cod_bank
                    eventName = `${Translate('bank')}: ${banksList.filter(bank => bank.cod_bank == codBankFromAccount)[0].trade_name}`
                } else return
            break;

            default: eventName = "modifier"
            break;
        }

        return (<h3>{Translate(eventName)}</h3>)
    }

    generateBankAccountStringLabel(idAccount){
        const { bankAccounts } = this.state;
        let inputAccountValue = ""
        const bankAccount = bankAccounts.filter(account => account.id_account == idAccount)[0]

        if(bankAccount == undefined) return ""

        if(bankAccount.account_digit == undefined || bankAccount.account_digit == '')
            inputAccountValue = `C/C ${bankAccount.account}`
        else inputAccountValue = `C/C ${bankAccount.account} - ${bankAccount.account_digit}`

        if(bankAccount.bank_branch_digit == undefined || bankAccount.bank_branch_digit == '')
            inputAccountValue += ` AG ${bankAccount.bank_branch}`
        else inputAccountValue += ` AG ${bankAccount.bank_branch} - ${bankAccount.bank_branch_digit}`

        return inputAccountValue
    }

    generateLabel(event){
        const {
            connectionModes,
            connectionActions,
        } = this.state;

        const structure = event.structures[0];

        if (
            !event.structures ||
            !event.structures.length ||
            !connectionActions.length ||
            !connectionModes.length ||
            event.codEvent == 6
        ) return ""

        if (!structure.codConnectionAction) return `: ${structure.label}`

        const connectionAction = connectionActions.find(
            connAction => connAction.id === structure.codConnectionAction
        ).description;

        if (!structure.codConnectionMode) return `: ${connectionAction}`;
        else {
            const connectionMode = connectionModes.find(
                connMode => connMode.codConnectionMode === structure.codConnectionMode
            ).label;
            
            return `: ${connectionMode} - ${connectionAction}`;
        }
    }

    renderEventCards() {
        const { interfaceEvents } = this.state;

        if (!interfaceEvents.length) return

        const generateStructureLabel = ({ structures }, property) => {
            let label;
    
            if (structures && structures.length) {
                structures.map(({ structureDetails }) => {
                    if (structureDetails && structureDetails.length) {
                        structureDetails.map(detail => {
                            if (detail[property]) {
                                label = detail[property];
                            }
                        });
                    }
                });
            }
    
            return label;
        }

        const generateBankAccountStringLabel = IdBankAccount => {return this.generateBankAccountStringLabel(IdBankAccount)}
        
        return interfaceEvents.map((event, index) => (
            <Draggable
                key={event.indPosition}
                draggableId={event.indPosition}
                index={index}
            >
                {(provided) => (
                    <TimelineIcon>
                        <TimelineCard className="success" ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Grid container direction="row" alignItems="center" spacing={24} style={{"padding": "15px 0"}}>
                                <Grid item xs={3}>
                                    <h4>{event.desEventName}</h4>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container direction="column" alignItems="center" spacing={8}>
                                        <Grid item xs={12} style={{"display": "inline-flex"}}>
                                            {this.generateEventNameTitle(event)}
                                            <h5>{this.generateLabel(event)}</h5>
                                        </Grid>
                                        {
                                            (function() {
                                                let inputLabel = generateStructureLabel(event, "input")

                                                return inputLabel ? (
                                                    <Grid item xs={12} style={{"display": "inline-flex"}}>
                                                        <h3>{Translate("input")}: </h3>
                                                        <h5>{inputLabel}</h5>
                                                    </Grid>
                                                ) : null
                                            })()
                                        }
                                        {
                                            (function() {
                                                if(event.codEvent == 6){
                                                    let bankStructures = event.structures[0].bankStructures
                                                    let IdBankAccount = bankStructures != undefined ? bankStructures.idBankAccount : undefined

                                                    let bankAccountLabel = generateBankAccountStringLabel(IdBankAccount)
                                                    return (<Grid item xs={12} style={{"display": "inline-flex"}}>
                                                                <h3>{bankAccountLabel}</h3>
                                                            </Grid>)
                                                }
                                                
                                                let outputLabel = generateStructureLabel(event, "output");
    
                                                return generateStructureLabel(event, "output") ? (
                                                    <Grid item xs={12} style={{"display": "inline-flex"}}>
                                                        <h3>{Translate("output")}: </h3>
                                                        <h5>{outputLabel}</h5>
                                                    </Grid>
                                                ) : null
                                            })()
                                        }
                                        {
                                            event.responsibleEmail ? (
                                                <Grid item xs={12} style={{"display": "inline-flex"}}>
                                                    <h3>{Translate("responsible")}: </h3>
                                                    <h5>{event.responsibleEmail}</h5>
                                                </Grid>
                                            ) : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div>
                                <IconButton
                                    onClick={() => { this.props.onClickEdit(event) }}
                                >
                                    <MdEdit size={23}/>
                                </IconButton>
                                <IconButton
                                    onClick={() => { this.props.onClickCopy(event) }}
                                >
                                    <MdFileCopy size={23}/>
                                </IconButton>
                                <IconButton
                                    onClick={() => { this.props.onClickDelete(event) }}
                                >
                                    <MdDelete size={23}/>
                                </IconButton>
                            </div>
                        </TimelineCard>
                        {provided.placeholder}
                    </TimelineIcon>
                )}
            </Draggable>
        ));
    }

    render() {
        return (  
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div>
                            <TimelineTitle>
                                {Translate("beginning")}
                            </TimelineTitle>
                            <TimelineDiv ref={provided.innerRef}>
                                <Scrollbars>
                                    {this.renderEventCards()}
                                    {provided.placeholder}
                                </Scrollbars>
                            </TimelineDiv>
                            <TimelineTitle>{Translate("end")}</TimelineTitle>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

const mapStateToProps = state => {
    const [interfaceEvents] = interfaceSelector.getEvents(state),
        [connectionModes] = globalSelector.getModes(state),
        [events] = globalSelector.getEvents(state),
        connectionActions = wizardStore.getConnectionActions(state);
    return {
        interfaceEvents,
        events,
        connectionModes,
        connectionActions
    };
}

const dispatchToProps = dispatch => {
    return {
        setInterfaceEvents: interfaceEvents => { dispatch(setInterfaceEvents(interfaceEvents)) }
    }
}

EventCard = connect(mapStateToProps, dispatchToProps)(EventCard);
export default EventCard;
