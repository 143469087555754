import React, {
    Component,
    Fragment
} from "react";

import {
    MdEdit,
    MdDelete,
    MdThumbUp,
    MdThumbDown,
    MdDescription,
    MdAddCircleOutline
} from "react-icons/md";

import {
    Grid,
    Avatar,
    Tooltip,
    CardMedia,
    CardHeader,
    DialogActions,
    DialogContent
} from "@material-ui/core/";

import {
    StyledCard,
    IconContainer,
    StyledCardActions,
    StyledDialogZIndex
} from "../interfaces-styles";

import { StyledDialogTitle } from "../../../components/conector-ui/conector-styled-components/styled-dialog";
import StyledButton from "../../../components/conector-ui/conector-styled-components/styled-button";

import Request from "../../../utils/request";
import Translate from "../../../i18n/translate";
import Session from "../../../utils/session-info";

import InterfaceApproveForm from "./interface-approve-form";
import RepositoryService from "./interface-repository-service";
import InterfacesCopyModal from "../interfaces-copy-modal/interfaces-copy-modal";

class InterfacesRepositoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            logo: null,
            documentation: null,
            repositoryItems: [],
            openCopyForm: false,
            targetInterface: null,
            targetItem: null,
            openApproveForm: false,
            canShowNotPublished: [1, 2, 18, 19].indexOf(Session().codUser) >= 0
        };
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems() {
        let { canShowNotPublished } = this.state;

        RepositoryService.FindAll(canShowNotPublished).then(repositoryItems => {
            this.setState({ repositoryItems: repositoryItems.map((repositoryItem) => {
                var {
                    codItem,
                    name,
                    codData
                } = repositoryItem;
    
                return (
                    <Grid key={"repository-item-" + codItem} item={true} xs={3}>
                        <StyledCard className="without-border">
                            <CardHeader title={name} avatar={
                                <Avatar>{ name.substring(0, 1) }</Avatar>
                            } />
                            <CardMedia image={`${Request.baseUrl}repositories/icon/${codData}?t=${Date.now()}`} />
                            { this.getItemActionButtons(repositoryItem) }
                        </StyledCard>
                    </Grid>
                )
            }) });
        });
    }

    getItemActionButtons = ({codItem, name, codData, published}) => {
        const {
            canShowNotPublished
        } = this.state;

        if (published) {
            return (
                <StyledCardActions>
                    <Tooltip placement="top" title={
                        <h3>{Translate("view_document")}</h3>
                    }>
                        <IconContainer onClick={this.handleOpenPreviewDoc.bind(this, codData)}>
                            <MdDescription size={24}/>
                        </IconContainer>
                    </Tooltip>
                    <Tooltip placement="top" title={
                        <h3>{Translate("get_template")}</h3>
                    }>
                        <div>
                            <IconContainer onClick={this.handleOpenCopyForm.bind(this, {codItem, name})}>
                                <MdAddCircleOutline size={24}/>
                            </IconContainer>
                        </div>
                    </Tooltip>
                    {
                        canShowNotPublished ? (
                            <Fragment>
                                <Tooltip placement="top" title={
                                    <h3>{Translate("remove")}</h3>
                                }>
                                    <IconContainer onClick={this.handleRemoveItem.bind(this, codItem)}>
                                        <MdDelete size={24}/>
                                    </IconContainer>
                                </Tooltip>
                                <Tooltip placement="top" title={
                                    <h3>{Translate("edit")}</h3>
                                }>
                                    <IconContainer onClick={this.handleEditItem.bind(this, {codItem, name, codData, published})}>
                                        <MdEdit size={24}/>
                                    </IconContainer>
                                </Tooltip>
                            </Fragment>
                        ) : null
                    }
                </StyledCardActions>
            );
        }

        return (
            <StyledCardActions>
                <Tooltip placement="top" title={
                    <h3>{Translate("deny")}</h3>
                }>
                    <IconContainer onClick={this.handleRemoveItem.bind(this, codItem)}>
                        <MdThumbDown size={24}/>
                    </IconContainer>
                </Tooltip>
                <Tooltip placement="top" title={
                    <h3>{Translate("approve")}</h3>
                }>
                    <div>
                        <IconContainer onClick={this.handleOpenApproveForm.bind(this, {codItem, name})}>
                            <MdThumbUp size={24}/>
                        </IconContainer>
                    </div>
                </Tooltip>
            </StyledCardActions>
        );
    }

    handleOpenCopyForm(targetItem) {
        RepositoryService.GetInterface(targetItem.codItem).then(targetInterface => {
            this.setState({
                openCopyForm: true,
                targetInterface,
                targetItem
            });
		});
    }

    handleOpenPreviewDoc(codData) {
        window.open(`${Request.baseUrl}repositories/doc/${codData}`, "_blank");
    }

    handleOpenApproveForm(targetItem) {
        this.setState({
            openApproveForm: true,
            targetItem
        });
    }

    handleEditItem(targetItem) {
        this.setState({
            openApproveForm: true,
            targetItem
        });
    }

    handleCloseApproveForm(_, reloadList) {
        this.setState({
            openApproveForm: false,
            targetItem: null
        });

        if (reloadList) {
            this.loadItems();
        }
    }

    handleRemoveItem(codItem) {
        RepositoryService.Remove(codItem).then(() => {
            this.loadItems();
        });
    }

    handleCloseCopyForm() {
        this.setState({
            openCopyForm: false,
            targetInterface: null
        });
    }

    handleEscapeKeyDown() {
        const {
            close,
            openCopyForm,
            openApproveForm
        } = this.state;

        if (openCopyForm) {
            this.handleCloseCopyForm();
        } else if (openApproveForm) {
            this.handleCloseApproveForm();
        } else {
            close(this);
        }
    }

    render() {
        const {
            close,
            targetItem,
            openCopyForm,
            targetInterface,
            openApproveForm,
            repositoryItems
        } = this.state;

        return (
            <Fragment>
                <StyledDialogZIndex fullWidth={true} maxWidth={"lg"} open={true} scroll="body" onEscapeKeyDown={this.handleEscapeKeyDown.bind(this)} onBackdropClick={close.bind(this)}>
                    <StyledDialogTitle id="repository-dialog-title" className="confirmation">
                        {
                            (() => {
                                if (openCopyForm) {
                                    return `${Translate("template")} - ${targetItem.name}`;
                                } else if (openApproveForm) {
                                    return `${Translate("template_approval")}`;
                                } else {
                                    return Translate("repository_templates");
                                }
                            })()
                        }
                    </StyledDialogTitle>
                    {
                        (() => {
                            if (openCopyForm) {
                                return <InterfacesCopyModal targetInterface={targetInterface} close={this.handleCloseCopyForm.bind(this)} />
                            } else if (openApproveForm) {
                                return <InterfaceApproveForm targetItem={targetItem} close={this.handleCloseApproveForm.bind(this)} />
                            } else {
                                return (
                                    <Fragment>
                                        <DialogContent>
                                            <Grid container={true} spacing={24}>
                                                {repositoryItems}
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <StyledButton variant="contained" color="primary" onClick={close.bind(this)}>
                                                {Translate("close")}
                                            </StyledButton>
                                        </DialogActions>
                                    </Fragment>
                                );
                            }
                        })()
                    }
                </StyledDialogZIndex>
            </Fragment>
        );
    }

}

export default InterfacesRepositoryModal;