import React, { Component, Fragment } from 'react'
import Translate from '../../i18n/translate'
import ScenarioSelectionModal from './scenario-selection-modal/scenario-selection-modal'
import Session from '../../utils/session-info'
import Typography from '@material-ui/core/Typography'
import { MdKeyboardArrowDown } from "react-icons/md";
import styled from 'styled-components'

const ColoredBold = styled.strong`
    && {
        color: #fe7f2d !important;
    }
`

class ScenarioButton extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props.appState,
            userAccessProfile: Session().userAccessProfile,
            openScenarioSelectionModal: false
        }
    }

    handleOpenModal = () => {
        this.setState({ openScenarioSelectionModal: true });
    }

    handleDismissModal = () => {
        this.setState({ openScenarioSelectionModal: false });
    }

    componentWillReceiveProps({ appState }) {
        this.setState({ ...appState });
    }

    render() {

        const { desInstance, desCompany, userAccessProfile } = Session();
        const { openScenarioSelectionModal } = this.state;

        return (
            <Fragment>
                <div className={'btnScenario'} onClick={this.handleOpenModal}>
                    <div>
                        <Typography>
                            {
                                desCompany
                                    ?
                                    <Fragment>
                                        {`${Translate('company')}: `}
                                        <strong>{desCompany}</strong>
                                    </Fragment>
                                    : <strong> {Translate("company_select")} </strong>
                            }
                        </Typography>
                        <Typography>
                            {
                                desInstance
                                    ?
                                    <Fragment>
                                        {`${Translate('instance')}: `}
                                        <strong>{desInstance}</strong>
                                    </Fragment>
                                    : <strong>{Translate("instance_select")}</strong>
                            }
                        </Typography>
                        <Typography>
                            <ColoredBold>
                                {userAccessProfile}
                            </ColoredBold>
                        </Typography>
                    </div>
                    <MdKeyboardArrowDown />
                </div>

                {
                    (() => {
                        if (openScenarioSelectionModal) {
                            return <ScenarioSelectionModal onDismiss={this.handleDismissModal.bind(this)} />
                        }
                    })()
                }
            </Fragment>
        )
    }
}

export default ScenarioButton