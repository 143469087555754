import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import StyledTextField from './conector-styled-components/styled-text-field'
import StyledInputLabel from './conector-styled-components/styled-input-label'
import { IconButton, InputAdornment, Icon } from '@material-ui/core/'
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import Translate from '../../i18n/translate'
import zxcvbn from 'zxcvbn'

const StyledDiv = styled.div`
    &.hidden { display: none; }
`

class ConectorInputPassword extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      ...props
    }
  }

  componentWillReceiveProps(props) {
    this.setState({...props})
  }

  handleChange = e => {
    if (this.state.onChange) {
      this.state.onChange(e)
    }
  }

  handleBlur = e => {
    if (this.state.onBlur) {
      this.state.onBlur(e)
    }
  }

  handleKeyPress = e => {
    if (this.state.onKeyPress) {
      this.state.onKeyPress(e);
    }
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleClickShowPasssword = () => {
    let state = { ...this.state }
    this.setState({ type: !state.type })
  }

  render() {
    const {
      errorCondition,
      required,
      label,
      name,
      help,
      classNameForm,
      type,
      disabled,
      value,
      startIcon,
      invisible,
      showPasswordStrength,
      endIcon
    } = this.state;

    if (Boolean(invisible)) {
      return null;
    }

    var classes,
        error    = Boolean(errorCondition),
        requiredField = Boolean(required);
    
    if (requiredField) {
      classes = `${classes || ''} required`;
    }

    if (error) {
      classes = `${classes || ''} error`;
    }

    let scoreIcon;
    if (showPasswordStrength && value.length) {
      const { score } = zxcvbn(value);
      
      if (score <= 1) {
        scoreIcon = 'sentiment_dissatisfied'
      } else if (score >= 2 && score < 4) {
        scoreIcon = 'sentiment_satisfied'
      } else if (score === 4) {
        scoreIcon = 'sentiment_very_satisfied'
      }
    }

    return (
      <StyledDiv className={classNameForm}>
        <StyledInputLabel className={classes}>{Translate(label)}</StyledInputLabel>
        <StyledTextField
          id={`conector-input-password-${name}`}
          helperText={Translate(help)}
          name={name}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          autoComplete='false'
          error={error}
          required={required}
          type={type ? 'text' : 'password'}
          variant='outlined'
          disabled={Boolean(disabled)}
          value={value}
          className={classes}
          InputProps={{
            startAdornment: (
              Boolean(startIcon) 
                ? 
                  <InputAdornment position="start">
                    <Icon>{startIcon}</Icon>
                  </InputAdornment>
                : ''
            ),
            endAdornment: (
              <InputAdornment position="end">
                {(() => {
                  if (Boolean(endIcon) && Boolean(scoreIcon)) {
                    return (
                      <Fragment>
                        <Icon className={scoreIcon}>{scoreIcon}</Icon>
                        <IconButton onClick={this.handleClickShowPasssword} onMouseDown={this.handleMouseDownPassword}>
                          { type ? <MdVisibilityOff /> : <MdVisibility /> }
                        </IconButton>
                      </Fragment>
                    );
                  } else if (Boolean(endIcon)) {
                    return (
                      <IconButton onClick={this.handleClickShowPasssword} onMouseDown={this.handleMouseDownPassword}>
                        { type ? <MdVisibilityOff /> : <MdVisibility /> }
                      </IconButton>
                    );
                  } else if (Boolean(scoreIcon)) {
                    return (
                      <Icon className={scoreIcon}>{scoreIcon}</Icon>
                    );
                  }
                })()}
              </InputAdornment>
            )
          }}
        />
      </StyledDiv>
    )
  }
}

export default ConectorInputPassword